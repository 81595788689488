export const catalog_ept_mexicana = [
    "1. Me supera emocionalmente este trabajo. ",
    "2. Me siento inútil y desilusionado frente al trabajo que desarrollo. ",
    "3. Me siento emocionalmente sin fuerzas. ",
    "4. No tengo ganas de ir a trabajar. ",
    "5. Me siento agotado física y mentalmente.",
    "6. Si algo he aprendido en mi trabajo como profesional de emergencias, es que las cosas no suceden como deberían. ",
    "7. Me he llegado a cuestionar mis propias creencias después de algunas intervenciones. ",
    "8. Mi trabajo me ha hecho ver que el mundo es injusto. ",
    "9. Este trabajo me ha enseñado que la vida termina dándole a uno lo que se merece. ",
    "10. Recuerdo hasta el nombre de algunos pacientes.",
    "11. A veces, pienso en aquellos pacientes que han fallecido mientras los atendía. ",
    "12. Guardo imágenes muy reales de aquellos accidentes que más me afectaron.",
    "13. En ocasiones me emociono debido a este trabajo.",
    "14. Durante algunas intervenciones llego a experimentar sentimientos de todo tipo. ",
]