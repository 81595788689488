export const sows_catalog = [
    "Sentirse mal",
    "Retortijones gástricos",
    "Espasmos musculares o sacudidas",
    "Sentir frialdad",
    "Sentir el latido cardiaco",
    "Tensión muscular",
    "Dolores",
    "Bostezos",
    "Moqueo/lagrimeo",
    "Insomnio/problemas para dormir"
]