import React, { Component } from 'react'
// import {Card } from 'antd'
import SideMenu from './side.doctors';

export class Doctors extends Component {
    render() {
        return (
            <div>
                <div>
                            <SideMenu />
                </div>
            </div>
        )
    }
}

export default Doctors
