export const ows_catalog = [
"Se siente enfermo",
"Vómitos",
"Falta de apetito",
"Boca seca",
"Retortijones gástricos",
"Inquietud",
"Fotosensibilidad",
"Cefalea",
"Adormecimiento",
"Aturdimiento o vértigos",
"Lipotimias o mareos",
"Rigidez de brazos o piernas",
"Sacudidas (contracciones) espontáneas de músculos",
"Temblores de manos",
"Sentimiento de frío",
"Sentimiento de irrealidad",
"Piel de gallina",
"Fases de frío y calor",
"Sudoración incrementada",
"Nariz congestionada",
"Alteraciones al inicio de la micción",
"Aumento de la diuresis",
"Latidos cardíacos audibles",
"Fatiga o cansancio",
"Tensión muscular",
"Dolores",
"Debilidad",
"Bostezos",
"Estornudos",
"Ojos llorosos",
"Insomnio",
]