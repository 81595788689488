export const thi_catalog = [
    "1 F. ¿Le resulta dificil concentrarse por culpa de su acúfeno?",
    "2.F. Debido a la intensidad del acúfeno ¿le cuesta oir a los demás?",
    "3.F. ¿Se enoja a causa de su acúfeno?",
    "4.F. ¿Le produce confusión su acúfeno?",
    "5.C. ¿Se encuentra desesperado por tener el acúfeno?",
    "6.E. ¿Se queja mucho por tener su acúfeno?",
    "7.F. ¿Tiene problemas para conciliar el sueño por su acúfeno?",
    "8.C. ¿Cree que su problema de acúfenos es insolucionable?",
    "9.F. ¿Interfiere su acúfeno en su vida social (salir a cenar, al cine)?",
    "10.E. ¿Se siente frustrado por su acúfeno?",
    "11.C. ¿Cree que tiene una enfermedad incurable?",
    "12.F. ¿Su acúfeno le impide disfrutar de la vida?",
    "13.F. ¿Interfiere su acúfeno en su trabajo o tareas del hogar?",
    "14.F. ¿Se siente a menudo irritable por culpa de su acúfeno?",
    "15.F. ¿Tiene dificultades para leer por culpa de su acúfeno?",
    "16.E. ¿Se encuentra usted triste debido a su acúfeno?",
    "17.E. ¿Cree que su acúfeno le crea tensiones o interfiere en su relación con la familia o amigos?",
    "18.F. ¿Es difícil para usted fijar su atención en cosas distintas a su acúfeno?",
    "19.C. ¿Cree que su acúfeno es incontrolable?",
    "20.F. ¿Se siente a menudo cansado por culpa de su acúfeno?",
    "21.E. ¿Se siente deprimido por culpa de su acúfeno?",
    "22.E. ¿Se siente ansioso por culpa de su acúfeno?",
    "23.C. ¿Cree que su problema de acúfenos le desborda?",
    "24.F. ¿Empeora su acúfeno cuando tiene estrés?",
    "25.E. ¿Se siente usted inseguro por culpa de su acúfeno?",
]