import React, { Component } from "react";
import SideMenu from "./side.hospitals";

export class Hospitales extends Component {
    
  render() {
    return (
      <div>
        <SideMenu />
      </div>
    );
  }
}

export default Hospitales;
