import React, { Component } from 'react'
// import {Card } from 'antd'
import SideMenu from './side.receptionists';

export class Receptionists extends Component {
    render() {
        return <SideMenu />
    }
}

export default Receptionists
